<template>
  <div>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li
          v-for="(breadcrumb, idx) in breadcrumbList"
          :key="idx"
          @click="routeTo(idx)"
          class="breadcrumb-item"
          :class="{'active': !!breadcrumb.link}"
        >{{ breadcrumb.name }}</li>
      </ol>
    </nav>
  </div>
  <!-- <ul>
   
  </ul>-->
</template>

<script>
export default {
  name: "Breadcrumb",
  data() {
    return {
      breadcrumbList: []
    };
  },
  mounted() {
    this.updateList();
  },
  watch: {
    $route() {
      this.updateList();
    }
  },
  methods: {
    routeTo(pRouteTo) {
      if (this.breadcrumbList[pRouteTo].link)
        this.$router.push(this.breadcrumbList[pRouteTo].link);
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    }
  }
};
</script>

<style lang="scss">
.breadcrumb-item.active {
  color: #145388;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>