<template>
  <div class="d-flex justify-content-between align-items-center">
    <img src="../../assets/image/logo.jpg" width="200" heigth="100">
    <el-menu
        class="el-menu-demo"
        mode="horizontal"
      >
        <el-submenu index="1">
          <template slot="title">
            <i class="fal fa-lg text-capitalize" v-bind:class="menu_header.icon"></i> {{menu_header.name}}
          </template>
          <div v-for="(item ,index) in menu_header.section" :key="index">
            <el-menu-item :index="'1-'+index" v-if="!item.subSection" :disabled="item.disabled" v-permission="item.permission">
              <router-link v-if="!item.disabled" :to="item.route">{{item.name}}</router-link>
              <div v-else>{{item.name}}</div>
            </el-menu-item>
            <el-submenu :index="'1-'+index" v-else :disabled="item.disabled" v-permission="item.permission">
              <template slot="title">{{item.name}}</template>
              <el-menu-item :index="'1-'+index+'-'+j" v-for="(sub ,j) in item.subSection" :key="j" v-permission="sub.permission">
                <router-link :to="sub.route">{{sub.name}}</router-link>
              </el-menu-item>
            </el-submenu>
          </div>
        </el-submenu>
        <el-menu-item index="2" v-permission="['office','administrator']">
          <a @click="listTasks">
            <i class="fal fa-tasks fa-lg"></i> Tasks
          </a>
        </el-menu-item>
        <el-menu-item index="3" v-permission="['office','administrator']">
          <a @click="listNotes">
            <i class="fal fa-file-alt fa-lg" aria-hidden="true"></i> Notes
          </a>
        </el-menu-item>
        <el-submenu index="4">
          <template slot="title">
            <i class="fal fa-lg fa-user text-capitalize"></i> {{$store.getters.name}}
          </template>
          <el-menu-item index="4-1">
            <router-link class="px-5 py-2" to="/admin/profile">Profile</router-link>
          </el-menu-item>
          <el-menu-item index="4-1">
             <a class="px-5 py-2" @click="logout">Logout</a>
          </el-menu-item>
        </el-submenu>
      </el-menu>
  </div>
</template>

<script>
import note from "@/services/api/note";
import auth from "@/services/api/auth";
export default {
  components: {

  },
  data() {
    return {
      toggle: null,
      alert: false,
      menu_header: 
        {
          name: "Admin",
          icon: "fa-user-cog",
          section: [
            {
              name: "Admin",
              permission: ["administrator", "office"],
              subSection: [
                {
                  name: "Sub Offices",
                  route: "/admin/office",
                  permission: ["administrator"],
                },         
                {
                  name: "Users",
                  route: "/admin/user",
                  permission: ["administrator", "office"],
                },
              ]
            }, 

            {
              name: "Clients",
              permission: ["administrator", "office"],
              subSection: [
                {
                  name: "Representatives",
                  route: "/admin/representative",
                  permission: ["administrator", "office"],
                },
                {
                  name: "Credentials",
                  route: "/admin/credential-input",
                  permission: ["administrator", "office"],
                },
                {
                  name: "Titles",
                  route: "/admin/title",
                  permission: ["administrator", "office"],
                },
                {
                  name: "Payment Option",
                  route: "/admin/payment-option",
                  permission: ["administrator", "office"],
                },
                {
                  name: "Payment Plan",
                  route: "/admin/payment-plan",
                  permission: ["administrator", "office"],
                },
              ]
            }, 

             {
              name: "Licenses",
              permission: ["administrator", "office"],
              subSection: [
                 {
                  name: "Licenses Types",
                  route: "/admin/license-type",
                  permission: ["administrator", "office"],
                },
              ]
            }, 

            {
              name: "Bank Statements",
              permission: ["administrator", "office"],
              subSection: [
                 {
                  name: "Bank Name",
                  route: "/admin/bank",
                  permission: ["administrator", "office"],
                },
                {
                  name: "Banks Accounts Types",
                  route: "/admin/bank-account",
                  permission: ["administrator", "office"],
                },
              ]
            }, 

            {
              name: "Sales Tax",
              permission: ["administrator"],
              subSection: [
                 {
                  name: "Forms",
                  route: "/admin/forms",
                  permission: ["administrator"],
                },
                {
                  name: "States Sales Tax",
                  route: "/admin/sale-tax/states",
                  permission: ["administrator"],
                },
              ]
            }, 

            {
              name: "Payroll Tax",
              route: "/admin/payroll",
              permission: ["administrator"],
              disabled: true,
              subSection: false
            }, 

            {
              name: "Corp Tax",
              permission: ["administrator", "office"],
              subSection: [
                 {
                  name: "Business Classification",
                  route: "/admin/clasification",
                  permission: ["administrator", "office"],
                },
                {
                  name: "Corporation Types",
                  route: "/admin/type",
                  permission: ["administrator", "office"],
                },
              ]
            }, 

             {
              name: "Insurance",
              permission: ["administrator"],
              subSection: [
                {
                  name: "Coverage",
                  route: "/admin/coverage",
                  permission: ["administrator"],
                },
                {
                  name: "Wholesaler",
                  route: "/admin/wholesaler",
                  permission: ["administrator"],
                },
                {
                  name: "Carrier",
                  route: "/admin/carrier",
                  permission: ["administrator"],
                },
                {
                  name: "Finance Companies",
                  route: "/admin/finance-companies",
                  permission: ["administrator"],
                },
              ]
            }, 

            {
              name: "Notifications",
              route: "/admin/notification",
              permission: ["administrator"],
            },   
          
          ]
        },
    };
  },
  methods: {
    logout() {
      auth.logout()
      .then(response => {
        this.$store.dispatch("logout");
        this.$router.push("/");
      })
      .catch(response=> {
        console.log('BACKEND FAIL LOGOUT');
        /*this.$store.dispatch("logout");
        this.$router.push("/");*/
      });

    },
    listNotes(){
      this.$router.push('/notes') 
    },
     listTasks(){
      this.$router.push('/tasks') 
    },
    handleCommand(command) {
      this.$message("click on item " + command);
    }
  }
};
</script>

<style lang="scss">
.alert {
  right: 0;
  width: 230px;
  top: 25px;
}

.el-dropdown-menu {
  
}

.el-dropdown-menu__item {
  line-height: 24px;
}

.el-submenu__title {
  color: #606266 !important;
}

.el-menu.el-menu--horizontal{
  border: none !important;
}

.el-submenu.is-active .el-submenu__title {
  border-bottom-color: transparent !important;
}
</style>