<template>
  <div class="h-100 main">
    <div id="background-opacity"></div>
    <!-- header -->
    <div class="bg-white shadow-sm px-5 py-1 position-relative side-user">
      <header-area v-on:todo="validate($event)" v-on:note="validate_note($event)"/>
    </div>
    
    <div class="d-flex content">
      <div ref="menu" class="d-flex">
        <menu-slide />
      </div>
      <div class="w-100 position-relative">
        <div class="position-absolute overflow-auto w-100 h-100">
          <div class="p-3">
            <div v-if="this.$route.name !=='Main' && this.$route.meta.withoutinfo!==true">
              <breadcrumbs />
            </div>
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuSlide from "@/components/layout/Menu";
import HeaderArea from "@/components/layout/Header";
import Breadcrumbs from "@/components/layout/Breadcrumbs";

export default {
  name: "adminLayout",
  components: {
    MenuSlide,
    HeaderArea,
    Breadcrumbs
  },
  data() {
    return {
      todo: false,
      note: false,
    };
  },
  beforeCreate() {
    
    document.body.className = "admin";
  },
  methods: {
    validate(event) {
      this.todo = event;
    },
    validate_note(event) {
      this.note = event;
    },
  }
};
</script>

<style lang="scss">

body{
    margin: 0 !important;
  }

#background-opacity {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 6; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.1); /* Black w/ opacity */
}
  
.admin {
  background: #f8f8f8;

  .side-user {
    z-index: 5;
  }

  .content {
    height: calc(100% - 99px);
  }

  .side-alert {
    width: 480px;
    > div {
      border-top-left-radius: 0.75rem;
      border-bottom-left-radius: 0.75rem;
      -webkit-box-shadow: -1px 1rem 3rem rgba(0, 0, 0, 0.175);
      box-shadow: -1px 1rem 3rem rgba(0, 0, 0, 0.175);
    }
  }

  .new-register {
    margin-left: -25px;
    left: auto;
    right: auto;
    bottom: 30px;
    z-index: 2;
  }
}
</style>

